import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export interface ProtectedRouteProps {
    isAllowed: boolean;
    redirectTo: string;
    redirectedFrom?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
    const { isAllowed, redirectTo, redirectedFrom } = props;

    if (!isAllowed) {
        return <Navigate to={redirectTo} state={{ redirectedFrom }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
