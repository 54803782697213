import React, { useEffect, useState } from 'react';
import { SocketContext, SocketContextProps } from 'contexts';
import { socketEvents } from 'socket/events';

export interface SocketProviderProps {
    children: React.ReactNode;
}

const SocketProvider: React.FC<SocketProviderProps> = props => {
    const [value, setValue] = useState<SocketContextProps>({
        isHost: false,
        isPlaying: false,
        track: null,
        users: []
    });

    useEffect(() => {
        return socketEvents({ setValue });
    }, []);

    return <SocketContext.Provider value={value}>{props.children}</SocketContext.Provider>;
};
export default SocketProvider;
