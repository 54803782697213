import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

export interface WithWaveformProps {
    waveform?: WaveSurfer;
    isWaveformReady?: boolean;
    container?: string;
    progressColor?: string;
    waveColor?: string;
}

function withWaveform<P extends object>(
    WrappedComponent: React.ComponentType<P>
): React.FC<P & WithWaveformProps> {
    return ({
        container = '#waveform',
        progressColor = '#2D5BFF',
        waveColor = '#EFEFEF',
        ...props
    }) => {
        const waveformRef = useRef<WaveSurfer | null>(null);

        const [isWaveformReady, setIsWaveformReady] = useState(false);

        useEffect(() => {
            if (!isWaveformReady) {
                waveformRef.current = WaveSurfer.create({
                    barWidth: 3,
                    cursorWidth: 1,
                    container,
                    height: 80,
                    progressColor,
                    waveColor,
                    cursorColor: 'transparent'
                });

                setIsWaveformReady(true);
            }
        }, [isWaveformReady, container, progressColor, waveColor]);

        return (
            <WrappedComponent
                {...(props as P)}
                waveform={waveformRef.current}
                isWaveformReady={isWaveformReady}
            />
        );
    };
}

export default withWaveform;
