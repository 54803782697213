import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Track } from 'types';
import TracksList from './TracksList';

export interface TracksProps {
    tracks: Track[];
    switchTrack: (track: Track) => void;
}

const Tracks: React.FC<TracksProps> = props => (
    <Card className="mb-4">
        <CardHeader tag="h5">Tracks</CardHeader>
        <CardBody>
            <TracksList tracks={props.tracks} onSwitchClick={props.switchTrack} />
        </CardBody>
    </Card>
);

export default Tracks;
