import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BACKEND_API_DOMAIN } from 'utilities/constants';

interface LoginState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: LoginState = {
    status: 'idle'
};

export const login = createAsyncThunk(
    'login/login',
    async (credentials: { username: string; password: string }) => {
        const encodedString = btoa(`${credentials.username}:${credentials.password}`);
        const response = await fetch(`${BACKEND_API_DOMAIN}/api/login`, {
            method: 'POST',
            headers: { Authorization: `Basic ${encodedString}` }
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        localStorage.setItem('authToken', `Basic ${encodedString}`);

        return response.json();
    }
);

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(login.pending, state => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, state => {
                state.status = 'succeeded';
            })
            .addCase(login.rejected, state => {
                state.status = 'failed';
            });
    }
});

export default loginSlice.reducer;
