import { createContext } from 'react';
import { Track, User } from 'types';

export interface SocketContextProps {
    isHost: boolean;
    isPlaying: boolean;
    track: Track | null;
    users: User[];
}

const SocketContext = createContext({} as SocketContextProps);

export default SocketContext;
