import React from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Track } from 'types';

export interface TracksListProps {
    tracks: Track[];
    onSwitchClick: (track: Track) => void;
}

const TracksList: React.FC<TracksListProps> = props => (
    <React.Fragment>
        {props.tracks.map((track, index) => (
            <div key={index} className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center flex-shrink-0 me-3">
                    <img
                        alt=""
                        src="https://placehold.co/50"
                        className="rounded me-3 bg-gray-200"
                    />
                    <div className="d-flex flex-column text-truncate">
                        <div className="text-dark line-height-normal mb-1 fw-bold">
                            {track.title}
                        </div>
                        <div className="small text-muted line-height-normal">{track.artist}</div>
                    </div>
                </div>
                <Button type="button" color="primary" onClick={() => props.onSwitchClick(track)}>
                    <FontAwesomeIcon icon={faPlay} />
                </Button>
            </div>
        ))}
    </React.Fragment>
);

export default TracksList;
