import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoginForm, { LoginFormHelpers, LoginFormValues } from './LoginForm';

export interface LoginProps {
    initialValues: LoginFormValues;
    onSubmit: (values: LoginFormValues, helpers: LoginFormHelpers) => void;
}

const Login: React.FC<LoginProps> = props => (
    <Container className="ps-md-0" fluid>
        <Row className="g-0">
            <Col className="d-none d-md-flex bg-primary" md="4" lg="6"></Col>
            <Col md="8" lg="6">
                <div className="login d-flex align-items-center py-5">
                    <Container>
                        <Row>
                            <Col md="9" lg="8" className="mx-auto">
                                <h3 className="mb-4">Welcome back!</h3>
                                <LoginForm
                                    initialValues={props.initialValues}
                                    onSubmit={props.onSubmit}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
    </Container>
);

export default Login;
