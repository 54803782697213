import React from 'react';
import { Badge, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { Track } from 'types';
import PlayerControls from './PlayerControls';

export interface PlayerProps {
    isHost: boolean;
    isPlaying: boolean;
    isReady: boolean;
    track: Track | null;
    pauseTrack: () => void;
    playTrack: () => void;
}

const Player: React.FC<PlayerProps> = props => (
    <Card className="mb-3">
        <CardBody>
            {props.isHost && <Badge color='success' className='mb-4'>Host</Badge>}
            <div id="waveform" />
        </CardBody>
        <CardFooter>
            <PlayerControls
                isDisabled={!props.isReady}
                isPlaying={props.isPlaying}
                onPauseClick={props.pauseTrack}
                onPlayClick={props.playTrack}
            />
        </CardFooter>
    </Card>
);

export default Player;
