import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, NavbarBrand } from 'reactstrap';

export interface TopNavbarProps {}

const TopNavbar: React.FC<TopNavbarProps> = props => (
    <Navbar color="primary" dark>
        <NavbarBrand href="/">
            <FontAwesomeIcon icon={faHome} />
        </NavbarBrand>
    </Navbar>
);

export default TopNavbar;
