import { Track } from 'types';
import socket from './index';

export const socketEvents = ({ setValue }: any) => {
    socket.on('isPlaying', (isPlaying: boolean) => {
        setValue((state: any) => {
            return { ...state, isPlaying };
        });
    });

    socket.on('switchTrack', (track: Track) => {
        setValue((state: any) => {
            return { ...state, track };
        });
    });

    socket.on('isHost', (isHost: boolean) => {
        setValue((state: any) => {
            return { ...state, isHost };
        });
    });
};
