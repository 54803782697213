import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Track } from 'types';
import { BACKEND_API_DOMAIN } from 'utilities/constants';
import { getAuthHeader } from 'utilities/helpers';

interface TracksState {
    tracks: Track[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: TracksState = {
    tracks: [],
    status: 'idle'
};

export const fetchTracks = createAsyncThunk('tracks/fetchTracks', async () => {
    const response = await fetch(`${BACKEND_API_DOMAIN}/api/tracks`, { headers: getAuthHeader() });
    const data = await response.json();
    return data as Track[];
});

const tracksSlice = createSlice({
    name: 'tracks',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTracks.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchTracks.fulfilled, (state, action: PayloadAction<Track[]>) => {
                state.status = 'succeeded';
                state.tracks = action.payload;
            })
            .addCase(fetchTracks.rejected, (state, action) => {
                state.status = 'failed';
            });
    }
});

export default tracksSlice.reducer;
