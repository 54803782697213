import React from 'react';
import { Container } from 'reactstrap';

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => (
    <footer className="text-center">
        <Container>Copyright © 2023</Container>
    </footer>
);

export default Footer;
