import React, { useEffect, useState } from 'react';
import { withWaveform } from 'hocs';
import { useAppDispatch, useAppSelector, useSocket } from 'hooks';
import socket from 'socket';
import { emitInteraction, emitPauseTrack, emitPlayTrack, emitSwitchTrack } from 'socket/emit';
import { fetchTracks } from 'store/tracks.slice';
import { Main } from 'views';
import WaveSurfer from 'wavesurfer.js';

export interface IndexPageProps {
    waveform?: WaveSurfer;
    isWaveformReady?: boolean;
}

const IndexPage: React.FC<IndexPageProps> = props => {
    const dispatch = useAppDispatch();

    const { tracks } = useAppSelector(state => state.tracks);

    const { isHost, isPlaying, track } = useSocket();

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (props.isWaveformReady) dispatch(fetchTracks());
    }, [props.isWaveformReady, dispatch]);

    useEffect(() => {
        if (props.isWaveformReady && props.waveform && track) {
            props.waveform.stop();
            console.log('🔥: Waveform stop');

            setIsReady(false);

            props.waveform.load(track.url);
            console.log('🔥: Waveform load ', track.url);
        }
    }, [props.isWaveformReady, props.waveform, track]);

    useEffect(() => {
        if (props.isWaveformReady && props.waveform) {
            props.waveform.on('interaction', (newTime: number) => {
                emitInteraction(newTime);
            });

            props.waveform.on('audioprocess', (newTime: number) => {
                if (props.isWaveformReady && props.waveform && isHost) {
                    const duration = props.waveform.getDuration();
                    const seekRatio = newTime / duration;

                    console.log('🔥: Waveform seeking to ', seekRatio);
                    // socket.emit('audioprocess', seekRatio);
                }
            });
        }
    }, [props.isWaveformReady, props.waveform]);

    useEffect(() => {
        if (props.isWaveformReady && props.waveform) {
            if (isPlaying) {
                console.log('🔥: Waveform play');
                props.waveform.play();
            } else {
                console.log('🔥: Waveform pause');
                props.waveform.pause();
            }
        }
    }, [props.isWaveformReady, props.waveform, isPlaying]);

    useEffect(() => {
        socket.on('interaction', (newTime: number) => {
            if (props.waveform) {
                const duration = props.waveform.getDuration();
                const seekRatio = newTime / duration;

                console.log('🔥: Waveform seeking to ', seekRatio);
                props.waveform.seekTo(seekRatio);
            }
        });
    }, [props.waveform]);

    useEffect(() => {
        if (props.isWaveformReady && props.waveform) {
            props.waveform.on('ready', () => {
                setIsReady(true);

                if (props.isWaveformReady && props.waveform && isPlaying) {
                    props.waveform.play();
                }
            });
        }
    }, [props.isWaveformReady, props.waveform, isPlaying, setIsReady]);

    return (
        <Main
            isHost={isHost}
            isPlaying={isPlaying}
            isReady={isReady}
            track={track}
            tracks={tracks}
            pauseTrack={emitPauseTrack}
            playTrack={emitPlayTrack}
            switchTrack={emitSwitchTrack}
        />
    );
};

export default withWaveform(IndexPage);
