import { Track } from 'types';
import socket from './index';

export const emitInteraction = (newTime: number) => {
    socket.emit('interaction', newTime);
};

export const emitPauseTrack = () => {
    socket.emit('pauseTrack');
};

export const emitPlayTrack = () => {
    socket.emit('playTrack');
};

export const emitSwitchTrack = (track: Track) => {
    socket.emit('switchTrack', track);
};
