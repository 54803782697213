import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';

export type LoginFormValues = {
    username: string;
    password: string;
};

export type LoginFormHelpers = FormikHelpers<LoginFormValues>;

export interface LoginFormProps {
    initialValues: LoginFormValues;
    onSubmit: (values: LoginFormValues, helpers: LoginFormHelpers) => void;
}

const LoginForm: React.FC<LoginFormProps> = props => (
    <Formik initialValues={props.initialValues} onSubmit={props.onSubmit}>
        {form => (
            <Form onSubmit={form.handleSubmit}>
                <FormGroup floating>
                    <Input
                        id="username"
                        type="text"
                        name="username"
                        placeholder="Username"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.username}
                        autoCapitalize="none"
                        autoComplete="none"
                        autoCorrect="none"
                        autoFocus
                    />
                    <Label for="username">Username</Label>
                </FormGroup>
                <FormGroup floating>
                    <Input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.password}
                    />
                    <Label for="password">Password</Label>
                </FormGroup>
                <Button color="primary" size="lg" type="submit" block>
                    Sign in
                </Button>
            </Form>
        )}
    </Formik>
);

export default LoginForm;
