import React from 'react';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from 'reactstrap';

export interface PlayerControlsProps {
    isDisabled: boolean;
    isPlaying: boolean;
    onPauseClick: () => void;
    onPlayClick: () => void;
}

const PlayerControls: React.FC<PlayerControlsProps> = props => (
    <ButtonGroup>
        {props.isPlaying && (
            <Button
                type="button"
                color="primary"
                disabled={props.isDisabled}
                onClick={props.onPauseClick}>
                <FontAwesomeIcon icon={faPause} />
            </Button>
        )}
        {!props.isPlaying && (
            <Button
                type="button"
                color="primary"
                disabled={props.isDisabled}
                onClick={props.onPlayClick}>
                <FontAwesomeIcon icon={faPlay} />
            </Button>
        )}
    </ButtonGroup>
);

export default PlayerControls;
