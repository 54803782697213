import React from 'react';
import { useAppSelector } from 'hooks';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Footer, ProtectedRoute, TopNavbar } from './components';
import { IndexPage, LoginPage } from './pages';

const App: React.FC = () => {
    const { status } = useAppSelector(state => state.login);

    const isLoggedIn: boolean = status === 'succeeded';

    return (
        <React.Fragment>
            <Routes>
                <Route
                    path="/"
                    element={<ProtectedRoute isAllowed={isLoggedIn} redirectTo="/login" />}>
                    <Route
                        index
                        element={
                            <React.Fragment>
                                <TopNavbar />
                                <IndexPage />
                                <Footer />
                            </React.Fragment>
                        }
                    />
                </Route>
                <Route
                    path="/login"
                    element={<ProtectedRoute isAllowed={!isLoggedIn} redirectTo="/" />}>
                    <Route index element={<LoginPage />} />
                </Route>
            </Routes>

            <ToastContainer />
        </React.Fragment>
    );
};

export default App;
