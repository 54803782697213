import React from 'react';
import { useAppDispatch } from 'hooks';
import { login } from 'store/login.slice';
import { Login, LoginFormValues } from 'views';

export interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
    const dispatch = useAppDispatch();

    const initialValues: LoginFormValues = {
        username: '',
        password: ''
    };

    const onSubmit = (values: LoginFormValues) => {
        dispatch(login(values));
    };

    return <Login initialValues={initialValues} onSubmit={onSubmit} />;
};

export default LoginPage;
