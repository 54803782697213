import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login.slice';
import tracksReducer from './tracks.slice';

const store = configureStore({
    reducer: {
        login: loginReducer,
        tracks: tracksReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
