import React from 'react';
import { RoundedHero } from '@typedbootstrap/ui-kit-pro-components';
import { Col, Container, Row } from 'reactstrap';
import { Track } from 'types';
import Player from './Player';
import Tracks from './Tracks';

export interface MainProps {
    isHost: boolean;
    isPlaying: boolean;
    isReady: boolean;
    track: Track | null;
    tracks: Track[];
    pauseTrack: () => void;
    playTrack: () => void;
    switchTrack: (track: Track) => void;
}

const Main: React.FC<MainProps> = props => (
    <React.Fragment>
        <RoundedHero bg="primary" color='light' title={props.track?.title || ''} text= {props.track?.artist || ''} dark>
            <Player
                isHost={props.isHost}
                isReady={props.isReady}
                isPlaying={props.isPlaying}
                track={props.track}
                pauseTrack={props.pauseTrack}
                playTrack={props.playTrack}
            />
        </RoundedHero>
        <Container className="px-5 mt-n10">
            <Row className="gx-5 justify-content-center">
                <Col lg="8">
                    <Tracks tracks={props.tracks} switchTrack={props.switchTrack} />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default Main;
